p:has(img) {
  display: flex;
  
  img {
    flex: 1 1 auto;
    width: 10px;
    object-fit: cover;
    margin-right: 16px;
    
    &:last-of-type {
      margin-right: 0;
    }
  }
}

.image--small {
  max-height: 600px;
  object-fit: contain;
}