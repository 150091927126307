// MEDIA QUERIES
@mixin screen($breakpoint) {
  $value: map-get($map: $breakpoints, $key: $breakpoint);

  @if $value != null {
    @media only screen and (max-width: $value) { @content; }
  } @else {
    @warn "The key '#{breakpoint}' could not be found in the '$breakpoints' map.";
  }
}
