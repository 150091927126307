.categories {
  margin-top: 3rem;
  margin-bottom: 3rem;
  &__container {
    display: flex;
    justify-content: space-between;

    @include screen(phone) {
      flex-flow: column;
    }
  }
}

.category {
  flex-grow: 1;
  margin-right: 4rem;
  height: 25rem;
  display: flex;
  align-items: flex-end;
  width: 20rem;
  background-size: cover;
  background-position: center;
  text-decoration: none;
  color: $white;

  @include screen(phone) {
    // background: none;
    // height: initial;
    width: 100%;
    margin-bottom: 1.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &:last-of-type {
    margin-right: 0;
  }

  &__title {
    background: $dark-grey;
    flex-grow: 1;
    text-align: center;
    padding: 3rem 0;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.8rem;
  }
}