* {
  font-size: 1.6rem;
  font-family: 'Montserrat', sans-serif;
  color: inherit;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $link;
}

h1 {
  font-weight: 800;
  line-height: initial;
  margin-bottom: 1rem;
  font-size: 4.2rem;

  @include screen(tablet) {
    font-size: 3.4rem;
  }
}
h2 {
  @extend h1;
  margin-bottom: .7rem;
  font-size: 3.4rem;

  @include screen(tablet) {
    font-size: 2.8rem;
  }
}
h3 {
  @extend h1;
  font-size: 2.4rem;
}
h4 {
  @extend h1;
  font-size: 1.9rem;
  font-weight: 400;
  font-style: italic;
  margin-bottom: .3rem;
}
h5 {
  @extend h4;
  font-style: normal;
  font-size: 1.3rem;
}
h6 {
  @extend h5;
  font-size: 1.1rem;
}
p {
  margin-bottom: 2.4rem;
}

blockquote {
  padding: 2rem 10rem;
  font-style: italic;
}

.heading {
  &--page {
    text-align: center;
    margin-bottom: 8rem;
    margin-top: 4rem;

    &::after {
      content: "";
      height: 2px;
      width: 20%;
      display: block;
      margin: 1rem auto;
      background: $color-primary;
    }
  }

  &--section {
    color: #a6a6a6;
    font-size: 1.8rem;
    margin-bottom: .7rem;
  }
}