// HARD RESET
* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// GENERAL SETTINGS
html {
  font-size: base-font(10);
}

body {
  box-sizing: border-box;
  position: relative;
  background-color: $grey;
  color: $white;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.site-content {
  flex: 1;
}
