.header {
  background: $grey;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: $light-grey solid 1px;
  
  a {
    text-decoration: none;
    color: $white;
  }

  &__container {
    display: flex;
    align-items: center;
  }

  &__logo {
    font-weight: 800;
    font-size: 1.8rem;
    text-transform: uppercase;
    flex-grow: 1;

    &:hover {
      color: $white;
    }
  }
}

.home-icon {
  width: 16px;
}

.nav {
  &__link {
    margin-left: 1.5rem;

    @include screen(phone) {
      margin-bottom: 1.5rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  
  @include screen(phone) {
    position: fixed;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $grey;
    transform: translateY(-100%);
    transition: transform .5s ease;
  }

}

.menu-icon {
  display: none;

  @include screen(phone) {
    display: block;
    z-index: 10;
  }

  &__bar {
    height: 0.3rem;
    width: 3rem;
    background-color: white;
    display: block;
    margin-bottom: 0.5rem;
    transition: transform .5s ease;
  }
}

