.footer {
  background: $grey;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-top: $light-grey solid 1px;

  &__container {
    display: flex;

    @include screen(phone) {
      flex-flow: column;
      align-items: center;
    }
  }
}

.copyright {
  flex-grow: 0;
}

.social {
  flex-grow: 1;

  @include screen(phone) {
    margin-bottom: 1.5rem;
  }

  &__link {
    margin-right: 1.5rem;
  }
  &__icon {
    height: 2.4rem;
  }
}