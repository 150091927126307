.grid {
  margin: -2rem 0 0 -2rem;
  display: flex;
  flex-wrap: wrap;
  @include screen(tablet) {
    display: block;
    flex-wrap: initial;
  }

  &__cell {
    margin: 2rem 0 0 2rem;

    &--fit {
      @extend .grid__cell;
      flex: 1;
    }

    &--1of4 {
      @extend .grid__cell;
      flex-basis: 25%;
    }

    &--1of3 {
      @extend .grid__cell;
      flex-basis: 33.3333%;
    }

    &--2of3 {
      @extend .grid__cell;
      flex-basis: 66.6666%;
    }

    &--1of2 {
      @extend .grid__cell;
      flex-basis: 49%;
    }

    &--full {
      @extend .grid__cell;
      flex-basis: 100%;
    }
  }
}