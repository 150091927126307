// SCREEN SIZES
$breakpoints: (
  'phone': 600px,
  'tablet': 768px,
);

// CONTAINER WIDTH
$container-width: 120rem;
$container-width-narrow: 102.4rem;

// COLOURS
$color-primary: #ee4e00;
$color-primary-dark: #2A2955;
$white: #FFF;
$black: #18181a;
$dark-grey: #26262a;
$grey: #3c3c43;
$light-grey: #61616c;
$link: #FF864C;
