// MARGINS
.u-margin-bottom {
  &--small {
    margin-bottom: 2rem;
    @include screen(tablet) {
      margin-bottom: 1rem;
    }
  }
  &--medium {
    margin-bottom: 5rem;
    @include screen(tablet) {
      margin-bottom: 3rem;
    }
  }
  &--big {
    margin-bottom: 10rem;
    @include screen(tablet) {
      margin-bottom: 5rem;
    }
  }
  &--huge {
    margin-bottom: 15rem;
    @include screen(tablet) {
      margin-bottom: 10rem;
    }
  }
}

.u-margin-top {
  &--small {
    margin-top: 2rem;
    @include screen(tablet) {
      margin-top: 1rem;
    }
  }
  &--medium {
    margin-top: 5rem;
    @include screen(tablet) {
      margin-top: 3rem;
    }
  }
  &--big {
    margin-top: 10rem;
    @include screen(tablet) {
      margin-top: 5rem;
    }
  }
  &--huge {
    margin-top: 15rem;
    @include screen(tablet) {
      margin-top: 10rem;
    }
  }
}

// CONTAINER
.container {
  max-width: $container-width;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 2rem;

  @include screen(tablet) {
    padding: 0 1rem;
  }

  &--narrow {
    max-width: $container-width-narrow;
    @extend .container;
  }
}
