nav a {
  &:hover {
    color: $color-primary;
  }
}

.button {
  &--primary {
    background: $color-primary;
    text-align: center;
    padding: .5rem 2rem;
    border-radius: .5rem;
    text-decoration: none;

    &:hover {
      color: $white;
      background: rgba($color-primary, 0.7);
    }
  }

  &--secondary {
    @extend .button--primary;
    background: none;
    border: 1px solid $color-primary;
    padding: .4rem 3rem;
    color: $color-primary;

    &:hover {
      color: $white;
      background: rgba($color-primary, 1);
    }
  }
}