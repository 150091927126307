.iframe-container {
  // overflow: hidden;
  // padding-top: 56.25%;
  // position: relative;

  & iframe {
    border: 0;
    // height: 100%;
    // left: 0;
    // position: absolute;
    // top: 0;
    width: 100%;
  }
}