body {
  display: flex;
}

.page-content {
  flex-grow: 1;
}

footer {
  flex-grow: 0;
}