.projects {
  list-style-type: none;
  margin-bottom: 5rem;
}

.project {
  display: flex;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  align-items: center;

  @include screen(phone) {
    flex-flow: row wrap;
  }

  &--dark {
    background: $black;
  }
  &--light {
    background: $dark-grey;
  }

  & > * {
    margin-right: 2rem;
    &:last-child {
      margin-right: 0;
    }
  }

  &__image {
    width: 7.2rem;
    height: 7.2rem;
    background-size: cover;
    background-position: center;
  }

  &__info {
    flex-grow: 1;

    @include screen(phone) {
      flex: 1 1 0;
    }
  }

  &__type {
    @include screen(phone) {
      display: none;
    }
  }

  &__link {
    @include screen(phone) {
      flex: 1 100%;
      margin-top: 1.5rem;
    }
  }

  &__author {
    color: $light-grey;
  }
}